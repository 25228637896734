
















import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { HttpMethod } from '@/shared/helpers/requests.helper'
import { getNodeApiUrlByVersion } from "@/config";
import CoursesCategory from "@/shared/modules/courses-categories/courses-category.model";

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CreateCoursesCategoriesDialog extends Vue {
  @Prop() meta!: any
  form: FormBase | null = null
  loading: boolean = false

  created() {
    this.form = new FormBase()
      .setEndpoint(`${getNodeApiUrlByVersion()}/course-categories`)
      .setTranslatable(true)
      .setMethod(HttpMethod.POST)
      .setModel(CoursesCategory)
      .setFields([
        new Field()
          .setKey('name')
          .setSize(FieldSizes.full)
          .isRequired()
          .setTranslatable(true)
          .setTitle(__('admin.components.courses.categories.create-dialog.form.name')),
      ])
      .setValidate(true)
      .setOnSuccess(this.meta.onSuccess)
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
  }
}
