









import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import { getNodeApiUrlByVersion } from "@/config";
import CoursesCategory from "@/shared/modules/courses-categories/courses-category.model";
import CoursesCategoryService from "@/shared/modules/courses-categories/courses-categories.service";
import CreateCoursesCategoriesDialog from "@/admin/components/courses/CreateCoursesCategoriesDialog.vue";
import EditCoursesCategoriesDialog from "@/admin/components/courses/EditCoursesCategoriesDialog.vue";


@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class CoursesCategoriesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(CoursesCategory)
      .setPerPage(30)
      .setEndpoint(`${getNodeApiUrlByVersion()}/course-categories`)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setText(__('admin.views.courses.categories.index.table.columns.name')),
      ])
      .setActions([
        new EditAction().setAction(this.openEditDialog),
        new DeleteAction().setAction(this.deleteCategory),
      ])
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateCoursesCategoriesDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.courses.categories.create-dialog.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(item: CoursesCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditCoursesCategoriesDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        item,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.courses.categories.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  deleteCategory(item: CoursesCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('admin.views.courses.categories.index.table.actions.delete.title'),
        text: __('admin.views.courses.categories.index.table.actions.delete.text', { name: item.name }),
        onYes: () => CoursesCategoryService.destroy(item.uuid)
          .then(() => {
            this.tableRef.refresh()
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('admin.views.courses.categories.index.table.actions.delete.on-success'),
            })
          })
          .catch(() => {
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.error,
              message: __('admin.views.courses.categories.index.table.actions.delete.on-error'),
            })
          })
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
